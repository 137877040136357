import React, { lazy } from "react";
import { nanoid } from "nanoid";
import { isMobile } from "~/utils/jstools";
// console.log("🚀 ~ isMobile:", isMobile())

// 硬件测试
import adminRoutes from "./deviceAdmin";

// 布局组件
const HomeLayout = lazy(() => import("~/layout/home"));

// 首页
// import Home from '~/views/home/index.jsx'
const Home = lazy(() => import("~/views/home/index"));
const Perfume = lazy(() => import("~/views/home/modules/perfumeView"));

const FormulaList = lazy(() => import("~/views/formulaList"));
const FormulaSubList = lazy(() => import("~/views/formulaList/sublist"));

// 高通量
const HTConfigure = lazy(() => import("~/views/devices/HT/configure"));
const HTController = lazy(() => import("~/views/devices/HT/controller"));
//连续流记录
const Record = lazy(() => import("~/views/devices/CF/record"));
const ContinuousFlowSettings = lazy(() =>
  import("~/views/devices/CF/settings")
);

// 水热
const ZZTConfigure = lazy(() => import("~/views/devices/ZZT/configure"));
const ZZTController = lazy(() => import("~/views/devices/ZZT/controller"));

// 水热B
const ZZT2Configure = lazy(() => import("~/views/devices/ZZT2/configure"));
const ZZT2Controller = lazy(() => import("~/views/devices/ZZT2/controller"));

// 量子点
const QuantumConfigure = lazy(() =>
  import("~/views/devices/QUANTUM/configure")
);
const QuantumController = lazy(() =>
  import("~/views/devices/QUANTUM/controller")
);

// 光催化
const PCConfigure = lazy(() => import("~/views/devices/PC/configure"));
const PCController = lazy(() => import("~/views/devices/PC/controller"));

const HFCRConfigure = lazy(() => import("~/views/devices/HFCR/configure"));
const HFCRController = lazy(() => import("~/views/devices/HFCR/controller"));

// 加热模组
// pc
const HighTempController = lazy(() =>
  import("~/views/devices/CyberChem/module-HighTemp/controller")
);
const HighTempConfigure = lazy(() =>
  import("~/views/devices/CyberChem/module-HighTemp/configure")
);
// h5
const HighTempControllerH5 = lazy(() =>
  import("~/views-h5/devices/CyberChem/module-HighTemp/controller")
);
const HighTempConfigureH5 = lazy(() =>
  import("~/views-h5/devices/CyberChem/module-HighTemp/configure")
);

const MultFlow = [
  // 业务路由
  {
    path: "/",
    key: nanoid(),
    element: <HomeLayout />,

    children: [
      {
        path: "",
        key: nanoid(),
        element: <Home />,
        name: "首页",
      },
      {
        path: "/perfume",
        key: nanoid(),
        element: <Perfume />,
        name: "AI调香系统",
      },
      {
        path: "/formulaList",
        key: nanoid(),
        element: <FormulaList />,
        name: "配方列表",
      },
      {
        path: "/formulaList/sublist",
        key: nanoid(),
        element: <FormulaSubList />,
        name: "配方列表",
      },
      ...adminRoutes,
    ],
  },

  {
    path: "/formulaList/HTConfigure",
    key: nanoid(),
    element: <HTConfigure />,
    name: "流程配置(木棉FS01)",
  },

  {
    path: "/formulaList/HTController",
    key: nanoid(),
    element: <HTController />,
    name: "配方合成(木棉FS01)",
  },
  {
    path: "/formulaList/record",
    element: <Record />,
    name: "连续流记录",
  },
  {
    path: "/formulaList/continuousFlowSettings",
    element: <ContinuousFlowSettings />,
    name: "连续流配置",
  },

  {
    path: "/formulaList/ZZTConfigure",
    key: nanoid(),
    element: <ZZTConfigure />,
    name: "流程配置(异木棉HT01)",
  },
  {
    path: "/formulaList/ZZTController",
    key: nanoid(),
    element: <ZZTController />,
    name: "配方合成(异木棉HT01)",
  },
  {
    path: "/formulaList/ZZT2Configure",
    key: nanoid(),
    element: <ZZT2Configure />,
    name: "流程配置(异木棉HT02)",
  },
  {
    path: "/formulaList/ZZT2Controller",
    key: nanoid(),
    element: <ZZT2Controller />,
    name: "配方合成(异木棉HT02)",
  },

  {
    path: "/formulaList/quantumConfigure",
    key: nanoid(),
    element: <QuantumConfigure />,
    name: "流程配置(木棉QD01)",
  },
  {
    path: "/formulaList/quantumController",
    key: nanoid(),
    element: <QuantumController />,
    name: "配方合成(木棉QD01)",
  },

  {
    path: "/formulaList/PCConfigure",
    key: nanoid(),
    element: <PCConfigure />,
    name: "流程配置(异木棉PC01)",
  },
  {
    path: "/formulaList/PCController",
    key: nanoid(),
    element: <PCController />,
    name: "配方合成(异木棉PC01)",
  },
  {
    path: "/formulaList/HFCRConfigure",
    key: nanoid(),
    element: <HFCRConfigure />,
    name: "流程配置(异木棉MC02)",
  },
  {
    path: "/formulaList/HFCRController",
    key: nanoid(),
    element: <HFCRController />,
    name: "配方合成(异木棉MC02)",
  },
  {
    path: "/formulaList/MHTController",
    key: nanoid(),
    element: isMobile() ? <HighTempControllerH5 /> : <HighTempController />,
    name: "流程配置（AIR-CHEM)",
  },
  {
    path: "/formulaList/MHTConfigure",
    key: nanoid(),
    element: isMobile() ? <HighTempConfigureH5 /> : <HighTempConfigure />,
    name: "配方合成（AIR-CHEM)",
  },
];

export default MultFlow;
